/* eslint-disable indent */
import { html, css, LitElement } from 'lit';
import { customElement, state, query } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import { loadDraftsIfNeeded, loadDraft } from '../../store/draft/draft.js';
import { loadCompositionsIfNeeded, loadComposition } from '../../store/comp/comp.js';
import { loadLtiItemsIfNeeded, loadLtiItem, loadSuppliers, loadKeySecrets } from '../../store/lti/lti.js';
import { loadScormItemsIfNeeded, loadScormItem } from '../../store/scorm/scorm.js';
import { loadOsItemsIfNeeded } from '../../store/os/os.js';
import { notifyDone } from '../../store/notify/notify.js';
import { navigated } from '../../store/route/route.js'
import { loadAccreditationInfo } from '../../store/accreditationInfo/accreditationInfo.js';
import { loadAccreditations } from '../../store/accreditation/accreditation.js';
import { getCatalogItemReplaceStatus } from '../../store/catalog/catalog.js';
import { Routes } from '@lit-labs/router';
import '@polymer/paper-toast/paper-toast.js';
import '../draft/sdb-composition-draft-list.js';
import '../draft/sdb-composition-draft.js';
import '../composition/sdb-composition-list.js';
import '../composition/sdb-composition.js';
import '../lti/sdb-lti-item-list.js';
import '../lti/sdb-lti-item.js';
import '../scorm/sdb-scorm-item-list.js';
import '../scorm/sdb-scorm-item.js';
import '../os/sdb-os-item-list.js';
import '../composition/sdb-accreditation-info.js';
import '../composition/sdb-composition-accreditations.js';

const mapStateToProps = (state) => ({
    userName: state.auth.user?.profile?.name,
    path: state.route.path,
    message: state.notify.text,
});

const mapDispatchToProps = (dispatch) => ({
    loadDraftsInitial: () => dispatch(loadDraftsIfNeeded({ reload: true })),
    loadDraft: (draftId) => dispatch(loadDraft({ draftId })),
    loadCompositionsInitial: () => dispatch(loadCompositionsIfNeeded({ reload: true })),
    loadComposition: (targetId) => dispatch(loadComposition({ targetId })),
    loadLtiItemsInital: () => dispatch(loadLtiItemsIfNeeded({ reload: true })),
    loadLtiItem: (id) => dispatch(loadLtiItem({ uniqueId: id })),
    loadSuppliers: () => dispatch(loadSuppliers()),
    loadKeySecrets: () => dispatch(loadKeySecrets()),
    loadScormItemsInital: () => dispatch(loadScormItemsIfNeeded({ reload: true })),
    loadScormItem: (targetId) => dispatch(loadScormItem({ targetId })),
    loadOsItemsInital: () => dispatch(loadOsItemsIfNeeded({ reload: true })),
    loadAccreditationInfo: (targetId) => dispatch(loadAccreditationInfo({ targetId })),
    loadAccreditations: (targetId) => dispatch(loadAccreditations({ targetId })),
    getCatalogItemReplaceStatus: (targetId) => dispatch(getCatalogItemReplaceStatus({ targetId })),
    navigated: () => dispatch(navigated()),
    notifyDone: () => dispatch(notifyDone()),
});

@customElement('sdb-composition-editor')
class SdbCompositionEditor extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @state()
    accessor path;

    @state()
    accessor message;

    @query('#editor_toast')
    accessor toast;

    constructor() {
        super();

        this._routes = new Routes(this, [
            {
                path: 'drafts',
                render: () => html`<sdb-composition-draft-list></sdb-composition-draft-list>`,
                enter: async () => {
                    this.loadDraftsInitial();
                }
            },
            {
                path: 'draft/:id',
                render: ({ id }) => html`<sdb-composition-draft draftId=${id}></sdb-composition-draft>`,
                enter: async ({ id }) => {
                    this.loadDraft(id);
                }
            },
            {
                path: 'compositions',
                render: () => html`<sdb-composition-list></sdb-composition-list>`,
                enter: async () => {
                    this.loadCompositionsInitial();
                }
            },
            {
                path: 'composition/:id',
                render: ({ id }) => html`<sdb-composition targetId=${id}></sdb-composition>`,
                enter: async ({ id }) => {
                    this.loadComposition(id);
                    this.getCatalogItemReplaceStatus(id);
                }
            },
            {
                path: 'composition/:id/accreditationinfo',
                render: ({ id }) => html`<sdb-accreditation-info targetId=${id}></sdb-accreditation-info>`,
                enter: async ({ id }) => {
                    this.loadAccreditationInfo(id);
                }
            },
            {
                path: 'composition/:id/accreditations',
                render: ({ id }) => html`<sdb-composition-accreditations targetId=${id}></sdb-composition-accreditations>`,
                enter: async ({ id }) => {
                    this.loadComposition(id);
                    this.loadAccreditations(id);
                }
            },
            {
                path: 'lti',
                render: () => html`<sdb-lti-item-list></sdb-lti-item-list>`,
                enter: async () => {
                    this.loadLtiItemsInital();
                }
            },
            {
                path: 'ltiitem/:id',
                render: ({ id }) => html`<sdb-lti-item ltiItemId=${id}></sdb-lti-item>`,
                enter: async ({ id }) => {
                    this.loadLtiItem(id);
                    this.loadSuppliers();
                    this.loadKeySecrets();
                }
            },
            {
                path: 'scorm',
                render: () => html`<sdb-scorm-item-list></sdb-scorm-item-list>`,
                enter: async () => {
                    this.loadScormItemsInital();
                }
            },
            {
                path: 'scormitem/:targetId',
                render: ({ targetId }) => html`<sdb-scorm-item targetId=${targetId}></sdb-scorm-item>`,
                enter: async ({ targetId }) => {
                    this.loadScormItem(targetId);
                }
            },
            {
                path: 'os',
                render: () => html`<sdb-os-item-list></sdb-os-item-list>`,
                enter: async () => {
                    this.loadOsItemsInital();
                }
            },
        ]);
    }

    set path(val) {
        if (val) {
            this._goto(val);
        }
    }

    set message(val) {
        if (val) {
            this._showMessage(val);
        }
    }

    static get styles() {
        return css`
            .toast {
                text-align: center;
            }
        `;
    }

    render() {
        return html`
            <div>${this._routes.outlet()}</div>
            <paper-toast id="editor_toast" class="toast"></paper-toast>
        `;
    }

    // todo: rewrite this with redux-saga
    async _goto(path) {
        if (!path) { return; }

        this.navigated();
        await this._routes.goto(path);

        const parentPath = (this._routes._parentRoutes || {})._currentPathname || '/';
        const basePath = parentPath !== '/'
            ? window.location.pathname.substring(0, window.location.pathname.lastIndexOf(parentPath) + parentPath.length)
            : '/';

        window.history.pushState({}, '', basePath + path);
    }

    // todo: logic and template should be in a new component
    _showMessage(text) {
        this.toast.text = text;
        this.toast.show();
        this.notifyDone();
    }
}

