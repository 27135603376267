const environments = {
    development: {
        draftEndpoint: 'https://compositionapi.test-sdbleerplatform.nl/api/1.0/draft/',
        draftsEndpoint: 'https://compositionapi.test-sdbleerplatform.nl/api/1.0/drafts',
        compositionsEndpoint: 'https://compositionapi.test-sdbleerplatform.nl/api/1.0/compositions',
        publishEndpoint: 'https://compositionapi.test-sdbleerplatform.nl/api/1.0/publish/',
        unpublishEndpoint: 'https://compositionapi.test-sdbleerplatform.nl/api/1.0/unpublish',
        rollbackReplaceEndpoint: 'https://compositionapi.test-sdbleerplatform.nl/api/1.0/rollback-replace/',
        elearningItemsEndpoint: 'https://compositionapi.test-sdbleerplatform.nl/api/1.0/elearningitems/',
        imageEndpoint: 'https://compositionapi.test-sdbleerplatform.nl/api/1.0/image/',
        portalsEndpoint: 'https://compositionapi.test-sdbleerplatform.nl/api/1.0/portals/',
        distributeEndpoint: 'https://compositionapi.test-sdbleerplatform.nl/api/1.0/distribute/',
        packageEndpoint: 'https://compositionapi.test-sdbleerplatform.nl/api/1.0/package/',
        scormPackageEndpoint: 'https://compositionapi.test-sdbleerplatform.nl/api/1.0/scormpackage/',
        scormPreviewEndpoint: 'https://compositionapi.test-sdbleerplatform.nl/api/1.0/scorm12/preview/',
        accreditationInfoEndpoint: 'https://tcgcatalogapi-dev.azurewebsites.net/api/1.0/accreditationinfo/',
        accreditationsEndpoint: 'https://tcgcatalogapi-dev.azurewebsites.net/api/1.0/accreditations/',
        accreditationProcessInfoEndpoint: 'https://tcgcatalogapi-dev.azurewebsites.net/api/1.0/accreditation/process/',
        microlearningStartUrlEndpoint: 'https://lticonsumer.test-sdbleerplatform.nl/api/1.0/tcgStartUrl',
        microlearningsEndpoint: 'https://lticonsumer.test-sdbleerplatform.nl/api/2.0/ltiItem',
        microlearningsInfoEndpoint: 'https://ltiprovider.sdbleerplatform.nl/api/1.0/internaltools',
        getLtiItemEndpoint: 'https://lticonsumer.test-sdbleerplatform.nl/api/2.1/ltiItem/',
        ltiItemEndpoint: 'https://lticonsumer.test-sdbleerplatform.nl/api/2.0/ltiItem/',
        ltiItemsEndpoint: 'https://lticonsumer.test-sdbleerplatform.nl/api/2.0/ltiItems',
        ltiSuppliersEndpoint: 'https://lticonsumer.test-sdbleerplatform.nl/api/1.0/ltiPortals/',
        keySecretsEndpoint: 'https://lticonsumer.test-sdbleerplatform.nl/api/2.0/keys/',
        ltiStartUrl: 'https://lticonsumer.test-sdbleerplatform.nl/api/1.0/startUrl',
        exportsEndpoint: 'https://lticonsumer.test-sdbleerplatform.nl/api/1.0/export/',
        catalogItemsEndpoint: 'https://tcgcatalogapi-dev.azurewebsites.net/api/1.0/catalogItems/',
        replaceStatusEndpoint: 'https://tcgcatalogapi-dev.azurewebsites.net/api/1.0/replacestatus/',
        previewEndpoint: 'https://compositionplayer.test-sdbleerplatform.nl/preview/'
    },
    production: {
        draftEndpoint: 'https://compositionapi.sdbleerplatform.nl/api/1.0/draft/',
        draftsEndpoint: 'https://compositionapi.sdbleerplatform.nl/api/1.0/drafts',
        compositionsEndpoint: 'https://compositionapi.sdbleerplatform.nl/api/1.0/compositions',
        publishEndpoint: 'https://compositionapi.sdbleerplatform.nl/api/1.0/publish/',
        unpublishEndpoint: 'https://compositionapi.sdbleerplatform.nl/api/1.0/unpublish',
        rollbackReplaceEndpoint: 'https://compositionapi.sdbleerplatform.nl/api/1.0/rollback-replace/',
        elearningItemsEndpoint: 'https://compositionapi.sdbleerplatform.nl/api/1.0/elearningitems/',
        imageEndpoint: 'https://compositionapi.sdbleerplatform.nl/api/1.0/image/',
        portalsEndpoint: 'https://compositionapi.sdbleerplatform.nl/api/1.0/portals/',
        distributeEndpoint: 'https://compositionapi.sdbleerplatform.nl/api/1.0/distribute/',
        packageEndpoint: 'https://compositionapi.sdbleerplatform.nl/api/1.0/package/',
        scormPackageEndpoint: 'https://compositionapi.sdbleerplatform.nl/api/1.0/scormpackage/',
        scormPreviewEndpoint: 'https://compositionapi.sdbleerplatform.nl/api/1.0/scorm12/preview/',
        accreditationInfoEndpoint: 'https://catalogapi.sdbleerplatform.nl/api/1.0/accreditationinfo/',
        accreditationsEndpoint: 'https://catalogapi.sdbleerplatform.nl/api/1.0/accreditations/',
        accreditationProcessInfoEndpoint: 'https://catalogapi.sdbleerplatform.nl/api/1.0/accreditation/process/',
        microlearningStartUrlEndpoint: 'https://lticonsumer.sdbleerplatform.nl/api/1.0/tcgStartUrl',
        microlearningsEndpoint: 'https://lticonsumer.sdbleerplatform.nl/api/2.0/ltiItem',
        microlearningsInfoEndpoint: 'https://ltiprovider.sdbleerplatform.nl/api/1.0/internaltools',
        getLtiItemEndpoint: 'https://lticonsumer.sdbleerplatform.nl/api/2.1/ltiItem/',
        ltiItemEndpoint: 'https://lticonsumer.sdbleerplatform.nl/api/2.0/ltiItem/',
        ltiItemsEndpoint: 'https://lticonsumer.sdbleerplatform.nl/api/2.0/ltiItems',
        ltiSuppliersEndpoint: 'https://lticonsumer.sdbleerplatform.nl/api/1.0/ltiPortals/',
        keySecretsEndpoint: 'https://lticonsumer.sdbleerplatform.nl/api/2.0/keys/',
        ltiStartUrl: 'https://lticonsumer.sdbleerplatform.nl/api/1.0/startUrl',
        exportsEndpoint: 'https://lticonsumer.sdbleerplatform.nl/api/1.0/export/',
        catalogItemsEndpoint: 'https://catalogapi.sdbleerplatform.nl/api/1.0/catalogItems/',
        replaceStatusEndpoint: 'https://catalogapi.sdbleerplatform.nl/api/1.0/replacestatus/',
        previewEndpoint: 'https://compositionplayer.sdbleerplatform.nl/preview/'
    },
};

export { environments as default };
