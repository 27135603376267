import { createAsyncThunk, createSlice, isFulfilled } from '@reduxjs/toolkit';
import { startListening } from '../listenerMiddleware.js';
import { distributeComposition } from '../comp/comp.js';
import { httpGet } from '../../helpers/http.js';

export const searchCatalogItems = createAsyncThunk(
    'catalog/search',
    async (args, { getState }) => {
        const { search, portals } = args;
        const { env, auth } = getState();

        const params = new URLSearchParams({ search: search });
        if (portals) {
            portals.forEach((id) => params.append('portals', id));
        }

        const response = await httpGet(`${env.settings.catalogItemsEndpoint}?${params}`, auth.user);

        return {
            list: response.data,
            total: response.data?.length,
            search: search,
        };
    },
    {
        condition: (_, { getState }) => {
            const { catalog: state } = getState();
            if (state.status === 'loading') {
                return false;
            }
        },
    },
);

export const getCatalogItemReplaceStatus = createAsyncThunk(
    'catalog/replacestatus',
    async (args, { getState }) => {
        const { targetId } = args;
        const { env, auth } = getState();

        const response = await httpGet(`${env.settings.replaceStatusEndpoint}${targetId}`, auth.user);

        return {
            status: response,
        };
    }
);

const catalogSlice = createSlice({
    name: 'catalog',
    initialState: {
        status: 'idle',
        continuePolling: false,
        replaceStatus: null,
        list: [],
        total: 0,
        error: null,
    },
    reducers: {
        clearSearchResults: (state) => {
            state.list = [],
            state.total = 0;
            state.search = '';
        },
        stopCatalogItemReplaceStatus: (state) => {
            state.continuePolling = false;
            state.replaceStatus = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchCatalogItems.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(searchCatalogItems.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list = payload.list,
                state.total = payload.total;
                state.search = payload.search;
            })
            .addCase(searchCatalogItems.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(getCatalogItemReplaceStatus.pending, (state) => {
                state.continuePolling = true;
            })
            .addCase(getCatalogItemReplaceStatus.fulfilled, (state, action) => {
                const { payload } = action;
                state.replaceStatus = payload.status;
            })
            .addCase(getCatalogItemReplaceStatus.rejected, (state, action) => {
                state.continuePolling = false;
                state.error = action.error;
            })
    },
});

export const {
    clearSearchResults,
    stopCatalogItemReplaceStatus,
} = catalogSlice.actions;

export default catalogSlice.reducer;

startListening({
    matcher: isFulfilled(getCatalogItemReplaceStatus),
    effect: async (action, { dispatch, getState, delay }) => {
        await delay(5000);

        const { status } = action.payload;
        const { catalog: state } = getState();

        if (status && status.overallStatus === 'inProgress' && state.continuePolling) {
            dispatch(getCatalogItemReplaceStatus({ targetId: status.replacementTargetId }));
        }
    },
});

startListening({
    matcher: isFulfilled(distributeComposition),
    effect: async (action, { dispatch }) => {
        const { targetId, log } = action.payload;

        if (log?.currentReplacedTargetId) {
            dispatch(getCatalogItemReplaceStatus({ targetId }));
        }
    },
});
